<template>
  <div>
    <form @submit.prevent="$emit('proceed')">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4 class="mb-3">Post Preview</h4>
        </div>
        <div class="col-md-12">
          <preview :property="preview_property" />
        </div>
        <div class="col-md-12 mt-4">
          <div class="form-group">
            <button class="btn btn-theme-light-2 rounded float-end">
              Post Property
            </button>
            <a
              href="#"
              @click.prevent="$emit('prev')"
              class="btn btn-theme-light-2 rounded"
            >
              Previous
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Preview from './Preview'

export default {
  components: {
    Preview
  },

  props: ['property'],

  computed: {
    preview_property() {
      return {
        images: this.property.images,
        name: this.property.name,
        image: this.property.main_image,
        amount: this.property.type == 'buy' ? this.property.amount : `${this.property.amount} / ${this.property.payment_frequency}`,
        region: this.property.region.name,
        date: 'now',
        type: this.property.type,
        qualities: this.property.qualities,
        details: this.property.details,
        description: this.property.description,
        amenities: this.property.amenities,
        video_url: this.property.video_url,
        posted_by: {
          id: this.property.agency.id,
          type: this.property.agency.type,
          name: this.property.agency.name,
          avatar: this.property.agency.avatar
        },
        contacts: {
          phone_number: this.property.phone_number,
          whatsapp: this.property.whatsapp,
          email: this.property.email
        }
      }
    }
  }
};
</script>
