<template>
  <div>
    <form @submit.prevent="proceed">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4 class="mb-3">Contact Information</h4>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Post property as</label>
            <v-select
              placeholder="Post property as"
              :options="agencies"
              label="name"
              v-model="selected_agency"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Phone Number To Contact</label>
            <input
              type="text"
              v-model="property.phone_number"
              placeholder="Phone Number"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Whatsapp number To Contact</label>
            <input
              type="text"
              v-model="property.whatsapp"
              class="form-control"
              placeholder="Whatsapp Number"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Email To Contact</label>
            <input
              type="text"
              v-model="property.email"
              class="form-control"
              placeholder="Email"
            />
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <div class="form-group">
            <button class="btn btn-theme-light-2 rounded float-end">
              Next
            </button>
            <a
              href="#"
              @click.prevent="$emit('prev')"
              class="btn btn-theme-light-2 rounded"
            >
              Previous
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected_agency: null,
      property: {}
    }
  },

  props: ['agencies'],

  methods: {
    proceed() {
      let data = {
        agency: this.selected_agency,
        phone_number: this.property.phone_number,
        whatsapp: this.property.whatsapp,
        email: this.property.email
      }

      this.$emit('next', data)
    }
  },

  watch: {
    selected_agency() {
      this.property.phone_number = this.selected_agency.phone_number
      this.property.whatsapp = this.selected_agency.whatsapp
      this.property.email = this.selected_agency.email
    }
  }
};
</script>
