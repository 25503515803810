<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section class="gray pt-4" v-if="! loading">
        <div class="container">
          <h2 class="mb-3">Submit Property</h2>
          <div class="row">
            <div class="col-lg-12 col-md-12 form-submit">
              <!-- 1st Step Checkout -->
              <div class="checkout-wrap">
                <div class="checkout-head">
                  <ul>
                    <li :class="step == 1 ? 'active' : ''">
                      <span><i class="fa fa-dot-circle-o"></i></span>Basic Information
                    </li>
                    <li :class="step == 2 ? 'active' : ''">
                      <span><i class="fa fa-dot-circle-o"></i></span>Images
                    </li>
                    <li :class="step == 3 ? 'active' : ''">
                      <span><i class="fa fa-dot-circle-o"></i></span>Details
                    </li>
                    <li :class="step == 4 ? 'active' : ''">
                      <span><i class="fa fa-dot-circle-o"></i></span>Contacts
                    </li>
                    <li :class="step == 5 ? 'active' : ''">
                      <span><i class="fa fa-dot-circle-o"></i></span>Preview
                    </li>
                  </ul>
                </div>

                <div class="checkout-body">
                  <template v-if="step == 1">
                    <basic-information :categories="categories" :regions="regions" @next="setData" />
                  </template>
                  <template v-if="step == 2">
                    <upload-images @next="setData" @prev="step = 1" />
                  </template>
                  <template v-if="step == 3">
                    <property-details :details="details" :amenities="amenities" @next="setData" @prev="step = 2" />
                  </template>
                  <template v-if="step == 4">
                    <property-contacts :agencies="agencies" @prev="step = 3" @next="setData" />
                  </template>
                  <template v-if="step == 5">
                    <property-preview :property="property" @prev="step = 4" @proceed="submit" v-if="! sending_data" />
                    <loading v-if="sending_data" message="Processing... Please wait..." />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <loading v-if="loading" />

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import BasicInformation from './submit-property/BasicInformation.vue'
import UploadImages from './submit-property/UploadImages.vue'
import PropertyDetails from './submit-property/PropertyDetails.vue'
import PropertyContacts from './submit-property/PropertyContacts.vue'
import PropertyPreview from './submit-property/PropertyPreview.vue'

export default {
  components: {
    AppHeader,
    AppFooter,
    BasicInformation,
    UploadImages,
    PropertyDetails,
    PropertyContacts,
    PropertyPreview
  },

  mounted() {
    if (!this.$auth.hasToken()) {
      window.location.href = this.$router.resolve({
        name: "login",
      }).href;
    }

    this.fetch();
  },

  data() {
    return {
      step: 1,
      property: {
        region: {},
        agency: {}
      },
      categories: [],
      regions: [],
      amenities: [],
      details: [],
      agencies: [],
      pricing: [],
      loading: true,
      sending_data: false,
      account: {},
      slug: ''
    };
  },

  methods: {
    submit() {
      this.sending_data = true

      let data = new FormData
      data.append('name', this.property.name)
      data.append('amount', this.property.amount)
      data.append('payment_frequency', this.property.payment_frequency)
      data.append('region_id', this.property.region.id)
      data.append('category_id', this.property.category.id)
      data.append('type', this.property.type)
      data.append('details', JSON.stringify(this.property.details))
      data.append('amenities', JSON.stringify(this.property.amenities))
      data.append('description', this.property.description)
      data.append('post_as_id', this.property.agency.id)
      data.append('post_as_type', this.property.agency.type)
      data.append('phone_number', this.property.phone_number)
      data.append('whatsapp', this.property.whatsapp)
      data.append('email', this.property.email)
      data.append('main_image', this.property.main_image)
      data.append('bedrooms', this.property.bedrooms)

      this.property.images.forEach((image, i) => {
        data.append(`images[${i}]`, image)
      })

      this.$axios.post('/api/v1/properties', data, {
        headers: { "content-type": "multipart/form-data" }
      }).then(response => {
        this.sending_data = false
        this.$router.push({ name: 'promote', params: { id: response.data.uniqid }})
      }).catch(() => {
        this.sending_data = false
      })
    },

    setData(data) {
      Object.keys(data).forEach(key => {
        this.property[key] = data[key]
      })

      this.step += 1
    },

    fetch() {
      this.loading = true

      this.$axios.get("/api/v1/properties/create").then((response) => {
        this.amenities = response.data.amenities;
        this.details = response.data.details;
        this.agencies = response.data.agencies;
        this.pricing = response.data.pricing;
        this.categories = response.data.categories;
        this.regions = response.data.regions;
        this.loading = false
      });
    }
  },

  watch: {
    step() {
      window.scroll(0, 0)
    }
  }
};
</script>
