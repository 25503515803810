<template>
  <div>
    <form @submit.prevent="proceed">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4 class="mb-3">Basic Information</h4>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="required">Title</label>
            <input
              type="text"
              class="form-control"
              placeholder="Write a descripive title for your property"
              v-model="property.name"
            />
            <span class="invalid-feedback d-block" v-if="errors.name">This field is required</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="required">Type</label>
            <select v-model="property.type" class="form-control" required>
              <option value="buy">For Sale</option>
              <option value="rent">For Rent</option>
              <option value="bnb">RealBnB</option>
              <option value="sharespace">ShareSpace</option>
            </select>
            <span class="invalid-feedback d-block" v-if="errors.type">This field is required</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="required">Category</label>
            <category-select
              :categories="categories"
              v-model="selected_category"
            />
            <span class="invalid-feedback d-block" v-if="errors.category">This field is required</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="required">Region</label>
            <region-select
              :regions="regions"
              v-model="selected_region"
              inputClass="form-control"
            />
            <span class="invalid-feedback d-block" v-if="errors.region">This field is required</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div :class="`col-md-${property.type != 'buy' ? '9' : '12'}`">
              <div class="form-group">
                <label class="required">Amount <small class="text-muted">Number in KES</small></label>
                <input
                  type="number"
                  v-model="property.amount"
                  placeholder="Amount"
                  class="form-control"
                />
                <span class="invalid-feedback d-block" v-if="errors.amount">This field is required</span>
              </div>
            </div>
            <div class="col-md-3" v-if="property.type != 'buy'">
              <div class="form-group">
                <label class="text-white">Frequency</label>
                <select
                  v-model="property.payment_frequency"
                  class="form-control"
                >
                  <option value="day">Per Day</option>
                  <option value="week">Per Week</option>
                  <option value="month">Per Month</option>
                  <option value="year">Per Year</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Video Url</label>
            <input type="text" v-model="property.video_url" placeholder="Video URL" class="form-control">
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="required">Description</label>
            <textarea
              class="form-control"
              placeholder="Property Description"
              v-model="property.description"
              rows="4"
            ></textarea>
            <span class="invalid-feedback d-block" v-if="errors.description">This field is required</span>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div class="form-group text-end">
            <button class="btn btn-theme-light-2 rounded">Next</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import CategorySelect from './CategorySelect.vue'
import RegionSelect from './RegionSelect.vue'

export default {
  components: {
    CategorySelect, RegionSelect
  },

  data() {
    return {
      property: { type: 'rent', payment_frequency: 'month' },
      selected_category: null,
      selected_region: null,
      errors: {}
    }
  },

  props: ['categories', 'regions'],

  methods: {
    proceed() {
      let data = {
        name: this.property.name,
        type: this.property.type,
        amount: this.property.amount,
        payment_frequency: this.property.payment_frequency,
        category: this.selected_category,
        region: this.selected_region,
        description: this.property.description
      }
    
      this.validate(data)

      if(Object.keys(this.errors).length == 0) {
        data.video_url = this.property.video_url
        
        this.$emit('next', data)
      }
    },

    validate(data) {
      this.errors = {}

      Object.keys(data).forEach(key => {
        if(data[key] == null) {
          this.errors[key] = key
        }
      })
    }
  }
};
</script>
