<template>
  <div>
    <span class="badge badge-info p-2" v-if="selected_parent != null"
      >{{ selected_parent.name }}
      <a
        href="#"
        @click.prevent="
          selected_parent = null;
          $emit('input', null);
        "
        class="ms-2"
        ><i class="lni lni-close text-white"></i></a
    ></span>
    <template v-if="!adding_region">
      <v-select
        :options="regions"
        label="name"
        placeholder="Select County"
        :class="inputClass"
        v-model="selected_parent"
        v-if="selected_parent == null"
      />
      <vue-select
        :options="selected_parent.children"
        label="name"
        placeholder="Select or type to add locality"
        :class="inputClass"
        v-model="selected_region"
        :county="selected_parent"
        v-if="selected_parent != null"
        @addNew="addRegion"
      />
    </template>
    <loading v-if="adding_region" class="inline" />
  </div>
</template>

<script>
import VueSelect from "@/components/vue-select-with-add/src/index";

export default {
  components: {
    VueSelect,
  },

  data() {
    return {
      selected_parent: null,
      selected_region: null,
      adding_region: false
    };
  },

  props: ["regions", 'inputClass'],

  methods: {
    addRegion(name) {
      this.adding_region = true

      let data = {
        name: name,
        parent: this.selected_parent.id,
      };

      this.$axios
        .post("/api/v1/properties/add-region", data)
        .then((response) => {
          this.selected_parent.children.push(response.data.region);
          this.selected_region = response.data.region;
          this.adding_region = false
        });
    },
  },

  watch: {
    selected_region() {
      this.$emit("input", this.selected_region);
    },

    selected_parent() {
      if (this.selected_parent == null) {
        this.selected_region = null;
        this.$emit("input", null);
      } else {
        if (this.selected_parent.children.length == 0) {
          this.$emit("input", this.selected_parent);
        }
      }
    },
  },
};
</script>
