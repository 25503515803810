<template>
  <img :src="image_preview" alt="image" style="width: 100%">
</template>

<script>
export default {
  mounted() {
    this.preview()
  },

  data() {
    return {
      image_preview: null
    }
  },

  props: ['image'],

  methods: {
    preview() {
      let reader = new FileReader

      reader.onload = e => {
        this.image_preview = e.target.result
      }

      reader.readAsDataURL(this.image)
    }
  },

  watch: {
    image() {
      this.preview()
    }
  }
}
</script>