<template>
  <div>
    <form @submit.prevent="proceed">
      <div class="row">
        <div class="col-md-12">
          <alert type="info">Images should be of size <strong>1280x850</strong> or maintain an aspect ratio of <strong>1:1.5</strong></alert>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4 class="mb-3">Upload Images</h4>
        </div>
        <div class="col-md-4">
          <label class="required">Select the main image</label>
          <input
            type="file"
            class="form-control mb-3"
            ref="main_image"
            @change="addMainImage"
          />
          <span class="invalid-feedback d-block" v-if="errors.main_image">This field is required</span>
          <image-preview :image="main_image" v-if="main_image != null" />
          <a
            href="#"
            class="btn btn-danger btn-sm mt-2"
            @click.prevent="main_image = null"
            v-if="main_image != null"
            ><i class="lni lni-trash-can"></i
          ></a>
        </div>
        <div class="col-md-8">
          <label class="required">Add More Images</label>
          <dropzone :multiple="true" @input="addImages" />
          <!-- <div id="upload-images" @click="selectImages">
            <a href="#" @click.prevent="selectImages"
              ><i class="lni lni-plus"></i> Add More Imges. Minimum 2</a
            >
          </div>
          <input
            type="file"
            style="display: none"
            id="upload-images-input"
            multiple
            ref="upload_images"
            @change="addImages"
          /> -->
          <span class="invalid-feedback d-block" v-if="errors.images">Add at least 2 images to continue</span>
          <div class="row mt-4">
            <div
              class="col-md-3 text-center"
              v-for="(image, i) in images_array"
              :key="`image-${i}`"
            >
              <image-preview :image="image" />
              <a
                href="#"
                class="btn btn-danger btn-sm mt-2"
                @click.prevent="removeImage(i)"
                ><i class="lni lni-trash-can"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <div class="form-group">
            <button class="btn btn-theme-light-2 rounded float-end">
              Next
            </button>
            <a
              href="#"
              @click.prevent="$emit('prev')"
              class="btn btn-theme-light-2 rounded"
            >
              Previous
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ImagePreview from "@/components/ImagePreview"
import Dropzone from '@/components/Dropzone'

export default {
  components: {
    ImagePreview, Dropzone
  },

  data() {
    return {
      images: [],
      main_image: null,
      preview_image: null,
      errors: {}
    }
  },

  methods: {
    addMainImage() {
      this.main_image = this.$refs.main_image.files[0];
    },

    addImages(files) {
      Object.values(files).forEach((image) => {
        this.images.push(image);
      });
    },

    selectImages() {
      document.getElementById("upload-images-input").click();
    },

    removeImage(i) {
      this.images.splice(i, 1);
    },

    proceed() {
      let data = {
        main_image: this.main_image,
        images: this.images_array
      }

      this.validate(data)

      if(Object.keys(this.errors).length == 0) {
        this.$emit('next', data)
      }
    },

    validate(data) {
      this.errors = {}

      if(data.main_image == null) {
        this.errors.main_image = 'main_image'
      }

      if(data.images.length < 2) {
        this.errors.images = 'images'
      }
    }
  },

  computed: {
    images_array() {
      return Object.values(this.images);
    },
  }
};
</script>

<style scoped>
#upload-images {
  padding: 5rem 0;
  cursor: pointer;
}
</style>