<template>
  <div class="dropzone" id="dropzone">
    <img
      class="dropzone-icon"
      src="https://wickedev.com/wp-content/uploads/2021/02/cloud-uploading.png"
    />

    Drop files or Click here to select files to upload.
    <input type="file" name="files" class="dropzone-input" multiple ref="selected_files" @change="selectFiles" />
  </div>
</template>

<script>
/* eslint-disable */
export default {
  mounted() {
    var dropzone = document.getElementById("dropzone");
    var dropzone_input = dropzone.querySelector(".dropzone-input");

    [
      "drag",
      "dragstart",
      "dragend",
      "dragover",
      "dragenter",
      "dragleave",
      "drop",
    ].forEach((event) => {
      dropzone.addEventListener(event, (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    });

    dropzone.addEventListener(
      "dragover",
      () => {
        dropzone.classList.add("dropzone-dragging");
      },
      false
    );

    dropzone.addEventListener(
      "dragleave",
      () => {
        dropzone.classList.remove("dropzone-dragging");
      },
      false
    );

    dropzone.addEventListener(
      "drop",
      (e) => {
        dropzone.classList.remove("dropzone-dragging");
        var files = e.dataTransfer.files;
        this.$emit('input', files)
      },
      false
    );

    dropzone.addEventListener("click", function (e) {
      dropzone_input.click();
    });
  },

  props: {
    multiple: {
      default() { return true }
    }
  },

  methods: {
    selectFiles() {
      let files = this.$refs.selected_files.files
      this.$emit('input', files)
    }
  }
};
</script>

<style lang="scss" scoped>
.dropzone {
  border: 0.2rem dashed #6583fe;
  padding: 2rem;
  border-radius: 0.25rem;
  background-color: #fff;
  text-align: center;
  font-size: 1.5rem;
  transition: 0.25s background-color ease-in-out;
  cursor: pointer;

  &-dragging,
  &:hover {
    background-color: lighten(#6583fe, 28%);
  }

  &-icon {
    max-width: 75px;
    display: block;
    margin: 0 auto 1.5rem;
  }

  &-input {
    display: none;
  }
}
</style>
